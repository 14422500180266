<template>
  <div>
    <NavigationBar titel="Organisationen" :actions="actions"></NavigationBar>
    <section>
      <TippCard
        hintID="Org_Liste_1_V1"
        titel="Neu: Standardorganisation auswählen!"
      >
        <template v-slot:content>
          <p class="body-2">
            Um nach dem Starten der Anwendung direkt eine Organisation zu
            öffnen, kannst Du eine Standardorganisation auswählen. Die Übersicht
            über Deine Mitgliedschaften in Organisationen wird damit
            übersprungen.
            <br /><br />
            Öffne jetzt Deine Profil- und Kontoeinstellungen und wähle Deine
            Standardorganisation aus.
          </p>
          <v-btn depressed class="mt-2" :to="{ name: 'settings-organizations' }"
            >Einstellungen öffnen<v-icon right>mdi-chevron-right</v-icon></v-btn
          >
        </template>
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div>
                <v-card>
                  <v-img
                    src="@/assets/images/dashboardHero.jpg"
                    max-height="300px"
                    gradient="to bottom left, rgba(35,38,56,.2), rgba(35,38,56,.8)"
                  >
                    <div class="mx-6">
                      <!-- <v-img
                        src="@/assets/images/orgLogo.png"
                        max-height="60px"
                        contain
                        class="mt-4"
                        position=""
                      >
                      </v-img> -->
                      <h3
                        class="mb-4 text-h3 font-weight-medium"
                        style="color: white; position: absolute; bottom: 0"
                      >
                        Willkommen, {{ firstName }} !
                      </h3>
                    </div>
                  </v-img>
                </v-card>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-domain"
                title="Deine Mitgliedschaften in Organisationen"
                :actions="menuActions"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="memberships"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  no-data-text="Du bist noch nicht Mitglied einer Organisation. Kontaktiere Deinen Administrator, um einer Organisation beizutreten."
                >
                  <template v-slot:default="props">
                    <v-card>
                      <v-list>
                        <v-list-item
                          v-for="item in props.items"
                          :key="item.organization.id"
                          link
                          :disabled="item.access.isDisabled"
                          :to="{
                            name: 'my-organization-dashboard',
                            params: {
                              organizationId: item.organization.id,
                            },
                          }"
                        >
                          <v-avatar
                            size="48"
                            :color="
                              $vuetify.theme.dark
                                ? 'grey darken-3'
                                : 'grey lighten-3'
                            "
                            rounded
                            class="mr-4"
                          >
                            <v-img
                              :src="item.organization.avatarUrl.size75x75 || ''"
                            ></v-img>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="title">
                              {{ item.organization.displayName }}
                              <v-icon
                                color="red darken-1"
                                :disabled="item.access.isDisabled"
                                small
                                v-if="item.organization.isPrefered"
                              >
                                mdi-star
                              </v-icon>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.organization.stationNumber }},
                              {{ item.organization.department }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text
                              v-if="item.access.isDisabled"
                            >
                              <span class="error--text subtitle-2 mr-2"
                                >Zugriff deaktiviert</span
                              >
                              <v-icon color="error">
                                mdi-alert-circle
                              </v-icon></v-list-item-action-text
                            >
                            <v-btn
                              v-else
                              color="red darken-1"
                              icon
                              :disabled="item.access.isDisabled"
                            >
                              <v-icon large> mdi-chevron-right </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <CheckLicense
      v-if="showCheckLicense"
      @close="toggleCheckLicense()"
    ></CheckLicense>
    <CustomDialog
      v-if="dialog"
      :dialog="dialog"
      title="Manueller Beitritt zu Organisation"
      text="Verwende die Einwahldaten aus der Einladung, die du per E-Mail zugesendet bekommen hast."
      width="s"
      :btnAction="openInviteWithParams"
      :btnDisabled="!helpers.formIsValid"
      btnText="Weiter"
      btnIcon="mdi-chevron-right"
      @close="toggleDialog()"
    >
      <template v-slot:content>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form
              v-model="helpers.formIsValid"
              ref="responseForm"
              @submit.prevent="openInviteWithParams()"
            >
              <v-text-field
                v-model.trim="invite.organizationId"
                outlined
                label="ID der Organisation*"
                required
                prepend-inner-icon="mdi-domain"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model.trim="invite.id"
                outlined
                label="ID der Einladung*"
                required
                prepend-inner-icon="mdi-account-key"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model.trim="invite.key"
                outlined
                label="Sicherheitschlüssel*"
                required
                prepend-inner-icon="mdi-key-variant"
                :rules="[rules.required]"
              ></v-text-field>
            </v-form>
            <small>* Erforderlich</small>
          </v-col>
        </v-row>
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import CheckLicense from "@/components/_system/helpers/CheckLicense.vue";
import { USER } from "../../store/modules";
import { GET_MEMBERSHIPS } from "../../store/action-types";

export default {
  name: "organization-list",
  components: {
    NavigationBar,
    Subheader,
    TippCard,
    CustomDialog,
    SupportTools,
    CheckLicense,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: this.createOrganization,
        },
      ],
      menuActions: [
        {
          title: "Manueller Beitritt",
          icon: "mdi-format-list-group-plus",
          actionStyle: "textBtn",
          function: this.toggleDialog,
          permission: true,
        },
      ],
      sortDesc: false,
      sortBy: "organization.stationNumber",
      showCheckLicense: false,
      helpers: {
        formIsValid: false,
      },
      dialog: false,
      invite: {
        organizationId: null,
        id: null,
        key: null,
      },
      rules: {
        required: (value) => !!value || "Ist erforderlich.",
      },
    };
  },
  computed: {
    firstName() {
      return this.$store.state.user.user.firstName;
    },
    memberships() {
      return this.$store.state.user.memberships;
    },
    code() {
      return [{ title: "memberships", data: this.memberships }];
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${USER}${GET_MEMBERSHIPS}`);
    },
    toggleDialog() {
      this.dialog = !this.dialog;
      this.invite = {
        organizationId: null,
        id: null,
        inviteKey: null,
      };
    },
    openInviteWithParams() {
      this.$router.push({
        name: "organization-join",
        params: {
          organizationId: this.invite.organizationId,
          inviteId: this.invite.id,
          inviteKey: this.invite.key,
        },
      });
    },
    createOrganization() {
      this.toggleCheckLicense();
    },
    toggleCheckLicense() {
      this.showCheckLicense = !this.showCheckLicense;
    },
  },
};
</script>
