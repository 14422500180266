<template>
  <div>
    <!-- 
      BANNER: Wenn organisation.verified = false || organisation.setupStatus === 'pending' || organisation.setupStatus === ''
     -->
    <!-- <v-banner single-line color="grey darken-3" @click:icon="alert" dark>
      <v-icon slot="icon" color="error" size="24"> mdi-alert </v-icon>
      Die Einrichtung deiner Organisation ist noch nicht abgeschlossen.
      <template v-slot:actions>
        <v-btn color="error" depressed>
          Einrichtung fortsetzen
          <v-icon right small>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-banner> -->
    <section v-if="loading">
      <layout-loading v-if="loading" :overlay="true"></layout-loading>
    </section>
    <section v-else-if="!loading && organizationInMaintenance">
      <v-container class="fill-height mt-16">
        <v-row align="top" justify="center">
          <v-col cols="12" sm="12" md="8">
            <div class="text-center">
              <v-avatar color="warning" rounded size="70" class="mb-4">
                <v-icon x-large color="white"> mdi-wrench-clock</v-icon>
              </v-avatar>
              <h5 class="text-h5 font-weight-medium">Wartungsmodus aktiv</h5>
              <p class="subtitle-1">
                Diese Organisation befindet sich im Wartungsmodus. Es können
                momentan keine Änderungen vorgenommen werden.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-else-if="!loading && !userHasPermission">
      <v-container class="fill-height mt-16">
        <v-row align="top" justify="center">
          <v-col cols="12" sm="12" md="8">
            <div class="text-center">
              <v-avatar color="error" rounded size="70" class="mb-4">
                <v-icon x-large color="white"> mdi-alert-octagon</v-icon>
              </v-avatar>
              <h5 class="text-h5 font-weight-medium">Zugriff verweigert</h5>
              <p class="subtitle-1">
                Du hast nicht die erforderlichen Berechtigungen für diese
                Aktion.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <router-view v-else />
  </div>
</template>

<script>
import LayoutLoading from "@/components/_system/helpers/Loading.vue";
import { auth } from "@/firebase";
import { ORGANISATION } from "@/store/modules.js";
import { GET_MEMBERSHIP, GET_ORGANIZATION } from "@/store/action-types.js";

export default {
  name: "organization-detail",
  components: {
    LayoutLoading,
  },
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$store.state.organization.loading;
    },
    permissions() {
      return (
        this.$store.state.organization.membership.access.permissions
          .allPermissionsIds || []
      );
    },
    userHasPermission() {
      const requiredPermissions = this.$route.matched
        .filter((route) => route.meta.permissionID)
        .map((route) => route.meta.permissionID);
      // console.log(requiredPermissions);
      // const permissionsOfUser =
      //   this.$store.state.organization.membership.access.allPermissionsIds ||
      //   [];

      // return requiredPermissions.every((permission) =>
      //   permissionsOfUser.includes(permission)
      // );
      return requiredPermissions.every((permission) =>
        this.$store.getters["organization/checkPermissionByID"](permission)
      );
    },
    organizationInMaintenance() {
      return this.$store.state.organization.activeOrganization.config
        .inMaintenance;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const organizationId = this.$route.params.organizationId;
      const userId = auth.currentUser.uid;

      // Using Promise.all to run both dispatches in parallel
      await Promise.all([
        this.$store.dispatch(`${ORGANISATION}${GET_MEMBERSHIP}`, {
          organizationId,
          userId,
        }),
        this.$store.dispatch(`${ORGANISATION}${GET_ORGANIZATION}`, {
          organizationId,
        }),
      ]);
      this.$store.commit(`${ORGANISATION}SET_LOADING`, false);
    },
  },
  // destroyed() {
  //   this.$store.dispatch(`${ORGANISATION}${UNSUBSCRIBE_MEMBERSHIP}`, {
  //     organizationId: this.$route.params.organizationId,
  //     userID: auth.currentUser.uid,
  //   });
  //   this.$store.dispatch(`${ORGANISATION}${UNSUBSCRIBE_ORGANISATION}`, {
  //     organizationId: this.$route.params.organizationId,
  //     userID: auth.currentUser.uid,
  //   });
  // },
};
</script>
